<template>
  <div class="AccessVisualizationClientDashboard">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CRow>
      <CCol>
        <h1><CBadge color="primary"><CIcon name="cil-center-focus" size="lg"/>  Visualisation - Que voit mon client ?</CBadge> </h1>
        <h1 class="text-dark">Bienvenue sur votre espace client </h1>
        <p>
          Cet espace est mis en place par <em>{{companyName}}</em> pour vous permettre de suivre en temps réel vos
          missions et votre facturation.<br>
          Il vous est également possible de charger des documents à la demande de votre cabinet.
        </p>
      </CCol>
    </CRow>

    <CRow class="font-dosis mt-3">
      <CCol sm="3">
        <CWidgetIcon
          text="missions en cours"
          color="primary"
          :icon-padding="false"
        >
          <strong>{{missions.length}}</strong>
        </CWidgetIcon>
      </CCol>
      <CCol sm="3">
        <CWidgetIcon
          text="demandes de documents en cours"
          color="info"
          :icon-padding="false"
        >
          <strong>{{nbDocsOnGoing}}</strong>
        </CWidgetIcon>
      </CCol>
      <CCol sm="3">
        <CWidgetIcon
          text="factures à payer"
          color="danger"
          :icon-padding="false"
        >
          <strong>{{nbFacturesToPay}}</strong>
        </CWidgetIcon>
      </CCol>
      <CCol sm="3">
        <CWidgetIcon
          text="reste à payer"
          color="warning"
          :icon-padding="false"
        >
          <strong>{{formatThousandSeparatorNumber(parseFloat(resteAPayer).toFixed(2))}} €</strong>
        </CWidgetIcon>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardHeader>
        <strong>📄 Vos missions en cours</strong>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          class="cursor-table"
          hover
          :items="missionsRender"
          :fields="missionsRenderFields"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
          @row-clicked="goToMissionPage"
        >

        <template #name="{item}">
          <td class="align-middle">
            {{item.name}}
          </td>
        </template>

        <template #types="{item}">
          <td class="align-middle">
            <em v-for="type in item.types" v-bind:key="type.id">{{type.name}} </em>
          </td>
        </template>

        <template #starting_date="{item}">
          <td class="align-middle text-center">
           <span> {{$dayjs(item.starting_date).format('D MMM YYYY')}} </span>
          </td>
        </template>

        <template #referent="{item}">
          <td class="align-middle text-center">
           {{item.referent}}
          </td>
        </template>

        <template #collaborateurs="{item}">
          <td class="align-middle">
            <em v-for="collaborateur in item.collaborateurs" v-bind:key="collaborateur.id">{{collaborateur.full_name}}, </em>
          </td>
        </template>

        <template #no-items-view>
          <CRow>
            <CCol class="text-center">
              Aucune mission en cours
            </CCol>
          </CRow>
        </template>
        </CDataTable>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>
        <strong>📃 Demandes de documents en cours</strong>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          class="cursor-table"
          hover
          :items="documentAskingsRender"
          :fields="documentAskingsRenderFields"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
          @row-clicked="goToDocumentAskingPage"
        >

        <template #mission_name="{item}">
          <td class="align-middle">
            {{item.mission_name}}
          </td>
        </template>

        <template #name="{item}">
          <td class="align-middle text-center">
            {{item.name}}
          </td>
        </template>

        <template #state="{item}">
          <td class="align-middle text-center">
            {{clientDocumentAskingsStateRender(item.state)}}
          </td>
        </template>

        <template #description="{item}">
          <td>
            <small>{{item.description}}</small>
          </td>
        </template>

        <template #no-items-view>
          <CRow>
            <CCol class="text-center">
              Aucune demande de documents en cours
            </CCol>
          </CRow>
        </template>

        </CDataTable>
      </CCardBody>
    </CCard>


    <CCard>
      <CCardHeader>
        <strong>💵 Vos factures</strong>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          class="cursor-table"
          hover
          :items="facturesRender"
          :fields="facturesRenderFields"
          @row-clicked="goToFacturePage"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
        >
          <template #mission_name="{item}">
            <td class="align-middle">
              <span> {{item.mission_name}} </span>
            </td>
          </template>
          <template #date_facturation="{item}">
            <td class="align-middle text-center">
              <span> {{$dayjs(item.date_facturation).format('D MMM YYYY')}} </span>
            </td>
          </template>
          <template #date_echeance="{item}">
            <td class="align-middle text-center">
              <span> {{$dayjs(item.date_echeance).format('D MMM YYYY')}} </span>
            </td>
          </template>
          <template #montant_total_ht="{item}">
            <td class="align-middle text-center">
              {{parseFloat(item.montant_total_ht).toFixed(2)}} €
            </td>
          </template>
          <template #montant_total_ttc="{item}">
            <td class="align-middle text-center">
              {{parseFloat(item.montant_total_ttc).toFixed(2)}} €
            </td>
          </template>
          <template #montant_total_paid="{item}">
            <td class="align-middle text-center">
              {{parseFloat(item.montant_total_paid).toFixed(2)}} €
            </td>
          </template>
          <template #left_to_pay="{item}">
            <td class="align-middle text-center">
              {{parseFloat(item.left_to_pay).toFixed(2)}} €
            </td>
          </template>

          <template #no-items-view>
          <CRow>
            <CCol class="text-center">
              Aucune facture
            </CCol>
          </CRow>
        </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { APIManagerConnected } from '@/api/APIManagerConnected'
import { APIUserConnected } from '@/api/APIUserConnected'

import renderMixins from '@/mixins/renderMixins'
import userMixins from '@/mixins/userMixins'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiManagerConnected = new APIManagerConnected()
const apiUserConnected = new APIUserConnected()

export default {
  name: 'AccessVisualizationClientDashboard',
  components: {
    Loading,
  },
  mixins: [
    renderMixins,
    userMixins
  ],
  data: function () {
    return {
      // ------------- MISSION -----------
      missions: [],
      missionsRender: [],
      missionsRenderFields: [
        { key: "name", label: "Intitulé mission", tdClass: 'ui-helper-center', sortable: true},
        { key: "types", label: "Type de mission", tdClass: 'ui-helper-center', sortable: true},
        { key: "starting_date", label: "Date de début de mission", tdClass: 'ui-helper-center', sortable: true},
        { key: "referent", label: "Référent mission", tdClass: 'ui-helper-center' },
        { key: "collaborateurs", label: "Collaborateurs affectés", tdClass: 'ui-helper-center' },
      ],
      isAllClientMissionsLoading: false,

      // ------------- DOCUMENT ASKINGS -----------
      documentAskings: [],
      documentAskingsRender: [],
      documentAskingsRenderFields: [
        { key: "mission_name", label: "Mission", tdClass: 'ui-helper-center', sortable: true},
        { key: "name", label: "Nom", tdClass: 'ui-helper-center', sortable: true},
        { key: "state", label: "Etat", tdClass: 'ui-helper-center', sortable: true},
        { key: "description", label: "Description", tdClass: 'ui-helper-center' },
      ],
      isAllDocumentAskingsLoading: false,

      // --------------- FACTURES --------------
      factures: [],
      facturesRender: [],
      facturesRenderFields: [
        { key: "mission_name", label: "Mission"},
        { key: "date_facturation", label: "Date de facturation", sortable: true },
        { key: "date_echeance", label: "Date d'échéance", sortable: true },
        { key: "montant_total_ht", label: "Montant total HT", sortable: true },
        { key: "montant_total_ttc", label: "Montant total TTC", sortable: true },
        { key: "montant_total_paid", label: "Montant payé", sortable: true },
        { key: "left_to_pay", label: "Reste à payer", sortable: true },
      ],
      isAllClientFacturesLoading: false,

      // -------------- STATISTICS --------------
      nbDocsOnGoing: 0,
      nbFacturesToPay: 0,
      resteAPayer: 0

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isAllClientMissionsLoading || this.isAllClientFacturesLoading || this.isAllDocumentAskingsLoading) {
        return true
      }
      return false
    },
    companyName () {
      return this.$store.state.user.companyName
    },
    isManager () {
      if (this.userGroupContains(['MANAGER'])) {
        return true
      }
      else {
        return false
      }
    }
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.getClientAllMissions()
    this.getClientAllDocumentAskingsNotValidated()

    if (this.isManager) {
      this.getClientAllFactures()
    }


  },
  watch: {
    missions: function (newMissions) {
      if (newMissions.length == 0) {
        this.missionsRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newMissions.length; i++) {
          final_array.push(
            {
              'id': newMissions[i].id,
              'name': newMissions[i].name,
              'types': newMissions[i].types,
              'starting_date': newMissions[i].starting_date,
              'referent': newMissions[i].referent_full_name,
              'collaborateurs': newMissions[i].collaborateurs,
              'is_paiement_alert': newMissions[i].is_paiement_alert
            }
          )
        }
        this.missionsRender = final_array
      }
    },

    factures: function (newFactures) {

      // PAIEMENT DETAILLED
      this.facturesRender = []

      for (var i = 0; i < newFactures.length; i++) {
        var temp_data = {
          'id': newFactures[i].id,
          'facture_number': newFactures[i].facture_number,
          'mission_name': newFactures[i].mission_name,
          'date_facturation': newFactures[i].date_facturation,
          'date_echeance': newFactures[i].date_echeance,
          'montant_total_ht': newFactures[i].montant_total_ht,
          'montant_total_ttc': newFactures[i].montant_total_ttc,
          'montant_total_paid': newFactures[i].montant_total_paid,
          'left_to_pay': parseFloat(newFactures[i].montant_total_ttc) - parseFloat(newFactures[i].montant_total_paid)
        }

        var today = this.$dayjs()
        var echeanceDate = this.$dayjs(newFactures[i].date_echeance)
        if (parseFloat(newFactures[i].montant_total_paid).toFixed(2) == parseFloat(newFactures[i].montant_total_ttc).toFixed(2)) {
          temp_data['_classes'] = 'table-info'
        }
        else if (today > echeanceDate) {
          temp_data['_classes'] = 'table-danger'
          this.nbFacturesToPay ++
          this.resteAPayer = parseFloat(this.resteAPayer) + parseFloat(newFactures[i].montant_total_ttc) - parseFloat(newFactures[i].montant_total_paid)
        }
        else {
          this.nbFacturesToPay ++
          this.resteAPayer = parseFloat(this.resteAPayer) + parseFloat(newFactures[i].montant_total_ttc) - parseFloat(newFactures[i].montant_total_paid)
        }
        this.facturesRender.push(temp_data)
      }
    },

    documentAskings: function (newDocumentAskings) {

      // PAIEMENT DETAILLED
      this.documentAskingsRender = []

      for (var i = 0; i < newDocumentAskings.length; i++) {
        var temp_data = {
          'id': newDocumentAskings[i].id,
          'mission_name': newDocumentAskings[i].mission_name,
          'name': newDocumentAskings[i].name,
          'state': newDocumentAskings[i].state,
          'description': newDocumentAskings[i].description,
        }

        if (newDocumentAskings[i].state == 'REFUSED') {
          temp_data['_classes'] = 'table-danger'
        }

        if (newDocumentAskings[i].state == 'REFUSED' || newDocumentAskings[i].state == 'CREATED' || newDocumentAskings[i].state == 'TRANSFERED') {
          this.nbDocsOnGoing ++
        }

        this.documentAskingsRender.push(temp_data)
      }
    }

  },
  methods: {
    // ----------------- GETTERS ---------------------------------------

    getClientAllMissions () {
      this.isAllClientMissionsLoading = true
      apiUserConnected.getClientAllMissions(this.token, this.$route.params.id)
      .then((result) => {
        this.missions = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllClientMissionsLoading = false
      })
    },

    getClientAllDocumentAskingsNotValidated () {
      this.isAllDocumentAskingsLoading = true
      apiUserConnected.getAllClientDocumentAskings(this.token, this.$route.params.id)
      .then((result) => {
        this.documentAskings = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllDocumentAskingsLoading = false
      })
    },

    getClientAllFactures () {
      this.isAllClientFacturesLoading = true
      apiManagerConnected.getClientAllFactures(this.token, this.$route.params.id)
      .then((result) => {
        this.factures = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllClientFacturesLoading = false
      })
    },

    // --------------- ROUTER FUNCTIONS -----------------------------------
    goToMissionPage(item) {
      this.$router.push('/visualisation/access-client/mission/' + item.id)
    },
    goToDocumentAskingPage(item) {
      this.$router.push('/visualisation/access-client/documents/' + item.id)
    },
    goToFacturePage(item) {
      this.$router.push('/visualisation/access-client/facture/' + item.id)
    },
  }

}
</script>
